import smoothscroll from 'smoothscroll-polyfill';
// import lottie from 'lottie-web';
import "@lottiefiles/lottie-player";
// import '@lottiefiles/lottie-interactivity';

require('jquery-validation');
require('simplebar');
let LottieInteractivity = require('@lottiefiles/lottie-interactivity');

require('./bootstrap');
require('./components/header');
require('./components/infos');
require('./components/services');
require('./components/navbar-handler');
require('ekko-lightbox');
// require('lottie-web');

$( document ).ready(function() {

	smoothscroll.polyfill();
                LottieInteractivity.create({
  mode: 'scroll',
  player:'#firstLottie',
  container: "#app",
  actions: [
    {
      visibility:[0, 0.25],
      type: "stop",
      frames: [0]
    },
    {
      visibility: [0.25, 1],
      type: "seek",
      frames: [0, 100]
    }
    ]
});
	// let animData = lottie.loadAnimation({
	// 	container: document.getElementById('logo'), // the dom element that will contain the animation
	// 	renderer: 'svg',
	// 	loop: false,
	// 	autoplay: false,
	// 	path: 'js/data.json', // the path to the animation json

	// });
	// console.log(animData)
	// let animationStart = 0;
	// $(window).scroll(function(){
	// 	// console.log(animationStart)
	//     animData.playSegments([animationStart,animationStart+1], true);
	//     animationStart++;
	// });
	// animData.addEventListener('loopComplete', function(){
	// 	console.log( "complete" );
	// 	// animData.goToAndStop(0,0);
	//     animationStart = 0;
	// })
	$(document).on('click', '[data-toggle="lightbox"]', function(event) {
	   	event.preventDefault();
	    $(this).ekkoLightbox();
	});
	
	if (window.matchMedia("(max-width: 991.98px)").matches) {

		$('.section-header').on('click', function() {
			  var card = $(this);
			setTimeout(function(){
				let cardTop = $(this).parent();
			  $('html,body').animate({
			    scrollTop: card.offset().top - 50
			  }, 250);
			}, 250);
		})
	    $('.collapse').on('shown.bs.collapse', function () {
		    var element = $(this).offset();
		    var headerOffset = 120;
		    var elementPosition = element.top;
		    var offsetPosition = elementPosition - headerOffset;

		    window.scrollTo({
		         top: offsetPosition,
		         behavior: "smooth"
		    });
	    })
	}

	$(".navbar .nav-link").on("click", function(){
	    if ($(this).data('href') !== undefined) {
			const section = $(this).data('href');
			// console.info($(section));
			$(section).get(0).scrollIntoView({behavior: "smooth"});
		}
		// console.info($(this).attr('href'));
	})

	function scrollToSection(item) {
		var diff=(item.offsetTop-window.scrollY)/4
		console.log(diff)
		if (Math.abs(diff)>1) {
		    window.scrollTo(0, (window.scrollY+diff))
		    clearTimeout(window._TO)
		    window._TO=setTimeout(scrollToItem, 30, item)
		} else {
		    window.scrollTo(0, item.offsetTop)
		}
	}

	$("#sendMail").on("click", function() {

		const submitButton = $(this);

	    if ($("#contactForm")[0].checkValidity()){

	    	const url = $("#contactForm").attr('action');
	    	const formData = $("#contactForm").serialize();

	    	$.ajax({
	    		url: url,
	    		type: 'POST',
	    		dataType: 'json',
	    		data: formData,
	    	})
	    	.done(function(data) {
	    		$('#sendMessage').html('<div class="alert alert-success rounded-0">Az üzenet sikeresen elküldve!</div>');
	    		$("#contactForm").trigger("reset");
	    		console.log(data);
	    	})
	    	.fail(function(xhr) {
	    		$('#sendMessage').html('');
	    		if(xhr.responseJSON.errors) {
	    		 	$.each(xhr.responseJSON.errors, function(key,value) {
				     	$('#sendMessage').append('<div class="alert alert-danger rounded-0">'+value+'</div');
				 	}); 
	    		} else $('#sendMessage').html('<div class="alert alert-danger">Hiba az üzenet elküldése közben. Kérlek próbáld újra később!</div>');
	    	})
	    	.always(function() {
	    		grecaptcha.reset();
	    	});
	    }
	    	
	    else{
	        $("#contactForm")[0].reportValidity()
	    }
	})
});