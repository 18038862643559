$( document ).ready(function() {

    // Open navbarSide when button is clicked //
    // $('#navbarSideButton').on('click', function() {
    //     $('#navbarSide').toggleClass('reveal');
    //     $('.overlay').toggleClass('d-block')
    // });

    // // Close navbarSide when the outside of menu is clicked
    // $('.overlay').on('click', function(){
    //     $('#navbarSide').removeClass('reveal');
    //     $('.overlay').toggleClass('d-block')
    // });

    // $(".sidebar-dropdown > a").click(function() {
    //     $(".sidebar-submenu").slideUp(200);

    //     if ( $(this).parent().hasClass("active") ) {
    //         $(".sidebar-dropdown").removeClass("active");
    //         $(this).parent().removeClass("active");
    //     } else {
    //         $(".sidebar-dropdown").removeClass("active");
    //         $(this).next(".sidebar-submenu").slideDown(200);
    //     }
    // });

    $('#navbarContent').on('hide.bs.collapse', function () {
        $('.hamburger').toggleClass('is-active');
    })
    $('#navbarContent').on('show.bs.collapse', function () {
        $('.hamburger').toggleClass('is-active');
    })
})