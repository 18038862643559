$(function(){

	$(window).on("load resize", function() {
	  if (this.matchMedia("(min-width: 768px)").matches) {
		setHeader();
	  } 
	});
	
	const hero = $('header');
 	const shrinkHeader = hero.height();

  	$(window).scroll(function() {
		setHeader();
  	});

	let setHeader = (function setHeader(){
		const loadingScreenLogo = $("#loading-screen-logo");
		const logoMaxWidth = parseFloat(loadingScreenLogo.css('max-width'));
  		const scroll = getCurrentScroll();

      	loadingScreenLogo.width( logoMaxWidth - (scroll) );
      	const headerHeight = $('header').height() / 2
      	// if(scroll > headerHeight ) {
			$('#headerContentText, #loadingScreenLogoLeft, #loadingScreenLogoRight, .navbar').css({
				'opacity': 0+((scroll - headerHeight )/300)
			});
			// $('').css({
			// 	'opacity': 0+((scroll - headerHeight )/300)
			// });
      	// }
	    return setHeader;

	}()); //auto-run

	function getCurrentScroll() {
	    return window.pageYOffset || document.documentElement.scrollTop;
    }
});