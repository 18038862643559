// require('iscroll');

$(window).ready(function(){
  initMenu();
  
  $('.product-item').on('click', function(){
  	const productId = $(this).data('productId');
  	const productDetails = $("#products").find(`[data-product-id='${productId}']`);
  	const tabPaneCategory = productDetails.parents('.tab-pane').parents('.tab-pane').attr('id');
  	const tabPaneSubcategory = productDetails.parents('.tab-pane').attr('id');

  	productDetails.parents('.carousel').carousel(productDetails.index())
  	$(`[href="#${tabPaneCategory}"]`).tab('show');
  	$(`[href="#${tabPaneSubcategory}"]`).tab('show');

  	$("#products").get(0).scrollIntoView({behavior: "smooth"});
  })
});


$(window).on("load resize", function() {
  if (this.matchMedia("(min-width: 768px)").matches) {
  	initMenu();

  }
});
$('.service-type-item').click(function(){
  const offset = $(this).offset();
  $('.service-item-hover').offset(offset);
});

const initMenu = function () {
  const initElem = $('.service-type-item.active');
  const initOffset = initElem.offset();
  const initSize = {
    width: initElem.css('width'),
    height: initElem.css('height')    
  }
  $('.service-item-hover')
    .offset(initOffset)
    .css(initSize)
}

function scrollToItem(item) {
    var diff=(item.offsetTop-window.scrollY)/4
    if (Math.abs(diff)>1) {
        window.scrollTo(0, (window.scrollY+diff))
        clearTimeout(window._TO)
        window._TO=setTimeout(scrollToItem, 30, item)
    } else {
        window.scrollTo(0, item.offsetTop)
    }
}