const section = $('#infos li');

function toggleAccordion() {
	if (window.matchMedia("(min-width: 992px)").matches) {
	  	section.removeClass('active');
	  	$(this).addClass('active');
	}
}

section.on('click', toggleAccordion);

$('.accordion-section ').on('click touch', function(){
	if (window.matchMedia("(max-width: 991.98px)").matches) {

		if($(this).find('.section-description').is( ":hidden" ) ){
			$('.section-description').slideUp('fast');
			$(this).find('.section-description').slideDown('fast');
		} else {
			$(this).removeClass('active');
			$(this).find('.section-description').slideUp('fast');
		}
	}
})
